export interface ISizes {
	title: string
	value: string
}

export const SIZES: ISizes[] = [
	{
		title: 'XXXL',
		value: 'XXXL',
	},
	{
		title: '4XS',
		value: '4XS',
	},
	{
		title: 'XS/XXS',
		value: 'XS/XXS',
	},
	{
		title: '37-40',
		value: '37-40',
	},
	{
		title: '6-12 Ay',
		value: '6-12 Ay',
	},
	{
		title: '12-24 Ay',
		value: '12-24 Ay',
	},
	{
		title: 'M/L/XL',
		value: 'M/L/XL',
	},
	{
		title: '65B',
		value: '65B',
	},
	{
		title: '150x200',
		value: '150x200',
	},
	{
		title: '2XS',
		value: '2XS',
	},
	{
		title: 'XXS',
		value: 'XXS',
	},
	{
		title: 'XXS - XS',
		value: 'XXS - XS',
	},
	{
		title: 'XS',
		value: 'XS',
	},
	{
		title: 'XS / S',
		value: 'XS / S',
	},
	{
		title: 'S',
		value: 'S',
	},
	{
		title: 'S / M',
		value: 'S / M',
	},
	{
		title: 'M',
		value: 'M',
	},
	{
		title: 'M/L',
		value: 'M/L',
	},
	{
		title: 'L/XL',
		value: 'L/XL',
	},
	{
		title: 'XL',
		value: 'XL',
	},
	{
		title: 'XL-XXL',
		value: 'XL-XXL',
	},
	{
		title: 'XL/2XL',
		value: 'XL/2XL',
	},
	{
		title: '2XL',
		value: '2XL',
	},
	{
		title: '2XL/3XL',
		value: '2XL/3XL',
	},
	{
		title: '3XL',
		value: '3XL',
	},
	{
		title: '3XL/4XL',
		value: '3XL/4XL',
	},
	{
		title: '4XL',
		value: '4XL',
	},
	{
		title: '5XL',
		value: '5XL',
	},
	{
		title: '6XL',
		value: '6XL',
	},
	{
		title: '7XL',
		value: '7XL',
	},
	{
		title: '8XL',
		value: '8XL',
	},
	{
		title: '9XL',
		value: '9XL',
	},
	{
		title: '10XL',
		value: '10XL',
	},
	{
		title: 'S/L',
		value: 'S/L',
	},
	{
		title: 'L/L',
		value: 'L/L',
	},
	{
		title: '1',
		value: '1',
	},
	{
		title: '2',
		value: '2',
	},
	{
		title: '3',
		value: '3',
	},
	{
		title: '4',
		value: '4',
	},
	{
		title: '5',
		value: '5',
	},
	{
		title: '22',
		value: '22',
	},
	{
		title: '24',
		value: '24',
	},
	{
		title: '25',
		value: '25',
	},
	{
		title: '26',
		value: '26',
	},
	{
		title: '27',
		value: '27',
	},
	{
		title: '28',
		value: '28',
	},
	{
		title: '29',
		value: '29',
	},
	{
		title: '30',
		value: '30',
	},
	{
		title: '31',
		value: '31',
	},
	{
		title: '32',
		value: '32',
	},
	{
		title: '33',
		value: '33',
	},
	{
		title: '34',
		value: '34',
	},
	{
		title: '35',
		value: '35',
	},
	{
		title: '36',
		value: '36',
	},
	{
		title: '37',
		value: '37',
	},
	{
		title: '38',
		value: '38',
	},
	{
		title: '39',
		value: '39',
	},
	{
		title: '40',
		value: '40',
	},
	{
		title: '41',
		value: '41',
	},
	{
		title: '42',
		value: '42',
	},
	{
		title: '43',
		value: '43',
	},
	{
		title: '44',
		value: '44',
	},
	{
		title: '45',
		value: '45',
	},
	{
		title: '46',
		value: '46',
	},
	{
		title: '47',
		value: '47',
	},
	{
		title: '48',
		value: '48',
	},
	{
		title: '49',
		value: '49',
	},
	{
		title: '50',
		value: '50',
	},
	{
		title: '51',
		value: '51',
	},
	{
		title: '52',
		value: '52',
	},
	{
		title: '53',
		value: '53',
	},
	{
		title: '54',
		value: '54',
	},
	{
		title: '56',
		value: '56',
	},
	{
		title: '57',
		value: '57',
	},
	{
		title: '58',
		value: '58',
	},
	{
		title: '75',
		value: '75',
	},
	{
		title: '80',
		value: '80',
	},
	{
		title: '85',
		value: '85',
	},
	{
		title: '90',
		value: '90',
	},
	{
		title: '95',
		value: '95',
	},
	{
		title: '100',
		value: '100',
	},
	{
		title: '105',
		value: '105',
	},
	{
		title: '110',
		value: '110',
	},
	{
		title: '9-12',
		value: '9-12',
	},
	{
		title: '30/30',
		value: '30/30',
	},
	{
		title: '32/32',
		value: '32/32',
	},
	{
		title: '33/32',
		value: '33/32',
	},
	{
		title: '34/32',
		value: '34/32',
	},
	{
		title: '35-38',
		value: '35-38',
	},
	{
		title: '35-39',
		value: '35-39',
	},
	{
		title: '35-40',
		value: '35-40',
	},
	{
		title: '36/32',
		value: '36/32',
	},
	{
		title: '36-39',
		value: '36-39',
	},
	{
		title: '36-40',
		value: '36-40',
	},
	{
		title: '36-41',
		value: '36-41',
	},
	{
		title: '36-42',
		value: '36-42',
	},
	{
		title: '36-43',
		value: '36-43',
	},
	{
		title: '36-44',
		value: '36-44',
	},
	{
		title: '37-44',
		value: '37-44',
	},
	{
		title: '39-42',
		value: '39-42',
	},
	{
		title: '40-44',
		value: '40-44',
	},
	{
		title: '40-45',
		value: '40-45',
	},
	{
		title: '41-44',
		value: '41-44',
	},
	{
		title: '41-45',
		value: '41-45',
	},
	{
		title: '42-44',
		value: '42-44',
	},
	{
		title: '44-46',
		value: '44-46',
	},
	{
		title: '46-48',
		value: '46-48',
	},
	{
		title: '48-50',
		value: '48-50',
	},
	{
		title: '50-52',
		value: '50-52',
	},
	{
		title: '52-54',
		value: '52-54',
	},
	{
		title: 'Standart',
		value: 'Standart',
	},
	{
		title: '70B',
		value: '70B',
	},
	{
		title: '75B',
		value: '75B',
	},
	{
		title: '75C',
		value: '75C',
	},
	{
		title: '75D',
		value: '75D',
	},
	{
		title: '80B',
		value: '80B',
	},
	{
		title: '80C',
		value: '80C',
	},
	{
		title: '80D',
		value: '80D',
	},
	{
		title: '85B',
		value: '85B',
	},
	{
		title: '85C',
		value: '85C',
	},
	{
		title: '85D',
		value: '85D',
	},
	{
		title: '90B',
		value: '90B',
	},
	{
		title: '90C',
		value: '90C',
	},
	{
		title: '90D',
		value: '90D',
	},
	{
		title: '95B',
		value: '95B',
	},
	{
		title: '95C',
		value: '95C',
	},
	{
		title: '95D',
		value: '95D',
	},
	{
		title: '100B',
		value: '100B',
	},
	{
		title: '100C',
		value: '100C',
	},
	{
		title: '100D',
		value: '100D',
	},
	{
		title: '105B',
		value: '105B',
	},
	{
		title: '75DD',
		value: '75DD',
	},
	{
		title: '80DD',
		value: '80DD',
	},
	{
		title: '85DD',
		value: '85DD',
	},
	{
		title: '90DD',
		value: '90DD',
	},
	{
		title: '11XL',
		value: '11XL',
	},
	{
		title: '12XL',
		value: '12XL',
	},
	{
		title: '90x90',
		value: '90x90',
	},
	{
		title: '32/30',
		value: '32/30',
	},
	{
		title: '31/30',
		value: '31/30',
	},
	{
		title: '100x100',
		value: '100x100',
	},
	{
		title: '9-10',
		value: '9-10',
	},
	{
		title: '7-8',
		value: '7-8',
	},
	{
		title: '5-6',
		value: '5-6',
	},
	{
		title: '11-12',
		value: '11-12',
	},
	{
		title: '3-4',
		value: '3-4',
	},
	{
		title: '13-14',
		value: '13-14',
	},
	{
		title: '4-5',
		value: '4-5',
	},
	{
		title: '6-7',
		value: '6-7',
	},
	{
		title: '6-9',
		value: '6-9',
	},
	{
		title: '0-3',
		value: '0-3',
	},
	{
		title: '8-9',
		value: '8-9',
	},
	{
		title: '3-6 Ay',
		value: '3-6 Ay',
	},
	{
		title: 'Tek Ebat',
		value: 'Tek Ebat',
	},
	{
		title: '9-12 Ay',
		value: '9-12 Ay',
	},
	{
		title: '70x180',
		value: '70x180',
	},
	{
		title: '10-11',
		value: '10-11',
	},
	{
		title: 'Yeni Doğan',
		value: 'Yeni Doğan',
	},
	{
		title: '12-13',
		value: '12-13',
	},
	{
		title: '24-36 Ay',
		value: '24-36 Ay',
	},
	{
		title: '36-40',
		value: '36-40',
	},
	{
		title: '12-18 AY',
		value: '12-18 AY',
	},
	{
		title: '2',
		value: '2',
	},
	{
		title: '8',
		value: '8',
	},
	{
		title: '4',
		value: '4',
	},
	{
		title: '9',
		value: '9',
	},
	{
		title: '5',
		value: '5',
	},
	{
		title: '6',
		value: '6',
	},
	{
		title: '12 AY',
		value: '12 AY',
	},
	{
		title: '6 AY',
		value: '6 AY',
	},
	{
		title: '3',
		value: '3',
	},
	{
		title: '10',
		value: '10',
	},
	{
		title: '12',
		value: '12',
	},
	{
		title: '0-6 AY',
		value: '0-6 AY',
	},
	{
		title: 'M/XL',
		value: 'M/XL',
	},
	{
		title: 'XL/S',
		value: 'XL/S',
	},
]
