import React from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../setup/hooks/redux'
import { setAddProduct } from '../reducers/ProductSlice'
import useTranslate from '../../../../_theme/helpers/translate'

const Status = () => {
	const translate = useTranslate()
	const dispatch = useDispatch()
	const { addProduct } = useAppSelector((state) => state.product)
	return (
		<div className='card card-flush py-4'>
			<div className='card-header'>
				<div className='card-title'>
					<h2>{translate('CARD.TEXT.STATUS')}</h2>
				</div>

				<div className='card-toolbar'>
					<div className='rounded-circle bg-success w-15px h-15px' id='kt_ecommerce_add_product_status'></div>
				</div>
			</div>

			<div className='card-body pt-0 scroll-x'>
				<select onChange={(e) => dispatch(setAddProduct({ ...addProduct, status: e.target.value }))} className='form-select mb-2 select2-hidden-accessible' defaultValue={addProduct.status}>
					<option value='published'>{translate('CARD.TEXT.PUBLISHED')}</option>
					<option value='unpublished'>{translate('CARD.TEXT.INACTIVE')}</option>
				</select>
			</div>
		</div>
	)
}

export default Status
